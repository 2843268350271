import { isArray } from 'lodash';

export const validateFileSizes = files => {
  const validFiles = [];
  const invalidFiles = [];

  [...files].map(item =>
    Math.round(parseInt(item.size, 10) / 1000) > 100
      ? invalidFiles.push(item)
      : validFiles.push(item)
  );
  return {
    validFiles,
    invalidFiles
  };
};

export const formDataFiles = files => {
  const formData = new FormData();
  if (isArray(files)) {
    files.forEach(file => formData.append(file.name, file));
  }
  return formData;
};

export const sendConfigurationFiles = (files, type) => {
  const path =
    type === 'test'
      ? '/assets_management_api/test_configurations'
      : '/assets_management_api/configurations';
  return fetch(path, {
    method: 'POST',
    body: formDataFiles(files)
  });
};

export const consolidateErrors = (maxSizeErrors, failedUploads) => {
  const maxSizeErrorFiles = maxSizeErrors.map(file => ({
    name: file.name,
    reason: 'max_size_exceeded'
  }));

  return [...maxSizeErrorFiles, ...failedUploads].map(file => ({
    name: file.name,
    error: file.reason
  }));
};

export const mapErrors = (row, translate) => [
  { children: row.name },
  { children: translate(`uploadConfigurations.fileErrorMessages.${row.error}`) }
];
