import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { isEmpty } from 'lodash/fp';
import { Body, Table } from '@sumup/circuit-ui';
import { Error as ErrorIcon, Success as SuccessIcon } from 'assets/icons';
import UserData from 'contexts/UserData';
import Loading from 'components/Loading';
import DefaultSection from 'components/DefaultSection';
import {
  sendConfigurationFiles,
  validateFileSizes,
  consolidateErrors,
  mapErrors
} from './UploadConfigurationsService';

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    max-width: 65%;
  }
  & > div {
    margin: 0 auto;
  }
  & > div > div {
    margin-bottom: 16px;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const ContentContainer = styled.div`
  margin-top: 1em;
`;

const TextLine = styled(Body)`
  display: flex;
  margin-left: 1em;
  align-items: center;

  & strong {
    margin-right: 0.5em;
  }
`;

const SmallIcon = css`
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5em;
`;

const ErrorIconSmall = styled(ErrorIcon)(SmallIcon);
const SuccessIconSmall = styled(SuccessIcon)(SmallIcon);

const ErrorsTable = styled(Table)`
  margin-top: 2em;
  margin-bottom: 3em;

  & th,
  & td {
    width: 50%;
  }
`;

const UploadConfigurations = ({ files, type, t: translate }) => {
  const { token } = useContext(UserData);
  const [errorFiles, setErrorFiles] = useState([]);
  const [acceptableFiles, setAcceptableFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [succeededUploads, setSucceededUploads] = useState(0);
  const [failedUploads, setFailedUploads] = useState(0);

  const uploadFiles = () => {
    setIsLoading(true);

    const { validFiles, invalidFiles } = validateFileSizes(files);
    // eslint-disable-next-line no-unused-expressions
    !isEmpty(invalidFiles) && setErrorFiles(invalidFiles);
    // eslint-disable-next-line no-unused-expressions
    !isEmpty(validFiles) && setAcceptableFiles(validFiles);

    sendConfigurationFiles(validFiles, type)
      .then(response =>
        response.status === 422 || response.status === 201
          ? response.json()
          : setRequestError(true)
      )
      .then(response => {
        const { succeeded, failed } = response;

        setSucceededUploads(succeeded);
        return setFailedUploads(failed);
      })
      .catch(() => setRequestError(true))
      .finally(() => setIsLoading(false));
  };

  const renderProcessedList = () => {
    const errorsList = consolidateErrors(errorFiles, failedUploads);
    const filesNotUploaded = files.length - succeededUploads;

    return (
      <ContentContainer>
        {!!succeededUploads && (
          <TextLine noMargin>
            <SuccessIconSmall />
            <Body noMargin variant="highlight">
              {succeededUploads}
            </Body>

            {translate('uploadConfigurations.someFilesUploadedMessage')}
          </TextLine>
        )}

        {!isEmpty(errorsList) && (
          <TextLine noMargin>
            <ErrorIconSmall />
            <Body noMargin variant="highlight">
              {filesNotUploaded}
            </Body>

            {translate('uploadConfigurations.errorFilesMessage')}
          </TextLine>
        )}

        <ErrorsTable
          noShadow
          headers={[{ children: 'File' }, { children: 'Error' }]}
          rows={errorsList.map(row => mapErrors(row, translate))}
        />
      </ContentContainer>
    );
  };

  const renderValidResponse = () =>
    isEmpty(errorFiles) && isEmpty(failedUploads) ? (
      <SectionContainer>
        <DefaultSection
          sectionImage={<SuccessIcon />}
          sectionTitle={`${acceptableFiles.length} ${translate(
            'uploadConfigurations.allFilesUploadedMessage'
          )}`}
          titleSize="two"
        />
      </SectionContainer>
    ) : (
      renderProcessedList()
    );

  const renderResponse = () =>
    isLoading ? <Loading /> : renderValidResponse();

  useEffect(() => {
    const controller = new AbortController();
    uploadFiles();

    return () => controller.abort();
  }, [token]);

  return requestError ? (
    <SectionContainer>
      <DefaultSection
        sectionImage={<ErrorIcon />}
        sectionTitle={translate('uploadConfigurations.processingErrorTitle')}
        sectionText={translate('uploadConfigurations.processingErrorText')}
        titleSize="two"
      />
    </SectionContainer>
  ) : (
    renderResponse()
  );
};

UploadConfigurations.propTypes = {
  files: PropTypes.array.isRequired,
  type: PropTypes.string,
  t: PropTypes.func
};

UploadConfigurations.defaultProps = {};

export default UploadConfigurations;
