import React from 'react';
import styled from '@emotion/styled';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import {
  SidebarSimCard,
  SidebarReverseLogistics,
  SidebarDeactivationReports,
  SidebarUploadReaderData,
  SidebarCarrierSuggestion,
  SidebarSelectedSimCard,
  SidebarSelectedReverseLogistics,
  SidebarSelectedDeactivationReports,
  SidebarSelectedUploadReaderData,
  SidebarSelectedCarrierSuggestion,
  SidebarSumupLogo,
  SoloReaderIcon,
  SoloReaderIconSelected
} from 'assets/icons';

export const SIDEBAR_WIDTH = '256px';

export const ICON_BASE_STYLES = `
  display: block;
  width: '18px';
  height: '18px';
`;

export const SumupLogo = styled(SidebarSumupLogo)`
  display: block;
  width: 82px;
  height: 24px;
`;

export const SimCardIcon = styled(SidebarSimCard)`
  ${ICON_BASE_STYLES};
  & rect {
    fill: transparent;
  }
  a:hover & > rect {
    fill: transparent;
    stroke: #d8dde1;
  }
`;

export const ReverseLogisticsIcon = styled(SidebarReverseLogistics)(
  ICON_BASE_STYLES
);
export const DeactivationReportsIcon = styled(SidebarDeactivationReports)(
  ICON_BASE_STYLES
);
export const UploadReaderDataIcon = styled(SidebarUploadReaderData)`
  ${ICON_BASE_STYLES};
  & path {
    fill: transparent;
  }
  ,
  a:hover > & * {
    fill: transparent;
    stroke: #d8dde1;
  }
`;

export const CarrierSuggestionIcon = styled(SidebarCarrierSuggestion)(
  ICON_BASE_STYLES
);
export const SelectedSimCardIcon = styled(SidebarSelectedSimCard)`
  ${ICON_BASE_STYLES};
  & > rect {
    fill: initial;
  }
`;

export const CardReaderIcon = styled(SoloReaderIcon)(ICON_BASE_STYLES);

export const SelectedReverseLogisticsIcon = styled(
  SidebarSelectedReverseLogistics
)(ICON_BASE_STYLES);
export const SelectedDeactivationReportsIcon = styled(
  SidebarSelectedDeactivationReports
)(ICON_BASE_STYLES);
export const SelectedUploadReaderDataIcon = styled(
  SidebarSelectedUploadReaderData
)(ICON_BASE_STYLES);
export const SelectedCarrierSuggestionIcon = styled(
  SidebarSelectedCarrierSuggestion
)(ICON_BASE_STYLES);
export const SelectedCardReaderIcon = styled(SoloReaderIconSelected)(
  ICON_BASE_STYLES
);

export const navigationItems = [
  {
    key: 'reader-connectivity',
    role: 'default',
    label: 'Reader connectivity',
    defaultIcon: <SimCardIcon />,
    selectedIcon: <SelectedSimCardIcon />,
    onClick: () =>
      navigate(ROUTES_PATH.SERIAL_NUMBER_SEARCH.ROOT, {
        state: {
          currentPageKey: 'reader-connectivity'
        }
      })
  },
  {
    key: 'upload-sim-cards',
    role: 'sim_cards_management',
    label: 'Upload SIM Cards',
    defaultIcon: <UploadReaderDataIcon />,
    selectedIcon: <SelectedUploadReaderDataIcon />,
    onClick: () =>
      navigate(ROUTES_PATH.UPLOAD_SIM_CARDS, {
        state: {
          currentPageKey: 'upload-sim-cards'
        }
      })
  },
  {
    key: 'carrier-suggestion',
    role: 'sim_cards_management',
    label: 'Carrier suggestion',
    defaultIcon: <CarrierSuggestionIcon />,
    selectedIcon: <SelectedCarrierSuggestionIcon />,
    onClick: () =>
      navigate(ROUTES_PATH.CARRIER_SUGGESTION, {
        state: {
          currentPageKey: 'carrier-suggestion'
        }
      })
  },
  // {
  //   key: 'deactivation-reports',
  //   role: 'sim_cards_management',
  //   label: 'Deactivation reports',
  //   defaultIcon: <DeactivationReportsIcon />,
  //   selectedIcon: <SelectedDeactivationReportsIcon />,
  //   onClick: () =>
  //     navigate(ROUTES_PATH.SIM_DEACTIVATION_REPORTS, {
  //       state: {
  //         currentPageKey: 'deactivation-reports'
  //       }
  //     })
  // },
  {
    key: 'reverse-logistics',
    role: 'sim_cards_management',
    label: 'Reverse logistics',
    defaultIcon: <ReverseLogisticsIcon />,
    selectedIcon: <SelectedReverseLogisticsIcon />,
    onClick: () =>
      navigate(ROUTES_PATH.REVERSE_LOGISTICS_FILE_CONVERTER, {
        state: {
          currentPageKey: 'reverse-logistics'
        }
      })
  },
  {
    key: 'reactivation-requests',
    role: 'sim_cards_management',
    label: 'Reactivation requests',
    defaultIcon: <DeactivationReportsIcon />,
    selectedIcon: <SelectedDeactivationReportsIcon />,
    onClick: () =>
      navigate(ROUTES_PATH.SIM_REACTIVATION_REQUESTS, {
        state: {
          currentPageKey: 'reactivation-requests'
        }
      })
  },
  {
    key: 'card-readers-configuration',
    role: 'card_readers_configuration',
    label: 'Readers Configuration',
    defaultIcon: <CardReaderIcon />,
    selectedIcon: <SelectedCardReaderIcon />,
    onClick: () =>
      navigate(ROUTES_PATH.CARD_READERS_CONFIGURATION, {
        state: {
          currentPageKey: 'card-readers-configuration'
        }
      })
  },
  {
    key: 'test-configuration',
    role: 'test_configuration',
    label: 'Test Configurations',
    defaultIcon: <CardReaderIcon />,
    selectedIcon: <SelectedCardReaderIcon />,
    onClick: () =>
      navigate(`${ROUTES_PATH.TEST_CONFIGURATIONS}/solo`, {
        state: {
          currentPageKey: 'test-configuration'
        }
      })
  }
];
